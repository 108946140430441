function Daily({}) {
  return (
    <div className="daily">
      <div className="halfs">
        <div className="left third">
          <div className="clients-header">
            <h2 className="hero-header small">Ежедневно</h2>
          </div>
          <div className="item">
            <div className="number">{">1000"}</div>
            <div className="text">
              сотрудников используют URT в работе для контроля 1600 локаций
            </div>
          </div>
          <div className="item">
            <div className="number">{">3000"}</div>
            <div className="text">
              принимает система по анкетам 60 типов по 1400 различным параметрам
            </div>
          </div>
          <div className="item">
            <div className="number">{">5000"}</div>
            <div className="text">
              тегированных фотографий снимаются и помещаются в базу
            </div>
          </div>
        </div>
        <div className="right">
          <div
            className="image"
            style={{ backgroundImage: "url(/images/woman.png)" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Daily;
