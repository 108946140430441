import { useCallback, useEffect, useState } from "react";
import Arrow from "../common/Arrow";
import Page from "./Page";
import Underlined from "../common/Underlined";

function Slider() {
  const [timer, setTimer] = useState(null);
  const [sliderClass, setSliderClass] = useState("page-1");

  const setNextSlide = useCallback(() => {
    const index = Number(sliderClass.split("-")[1]);
    let nextIndex = index + 1;
    const button = document.getElementById(`page-${nextIndex}`);
    if (button) {
      setSliderClass(`page-${nextIndex}`);
    } else {
      setSliderClass(`page-1`);
    }
  }, [sliderClass]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    const newTimer = setTimeout(setNextSlide, 8000);
    setTimer(newTimer);
  }, [sliderClass]);

  const onClick = useCallback((e) => {
    setSliderClass(e.target.id);
  }, []);

  return (
    <div className="slider">
      <div className={`slides-holder ${sliderClass}`}>
        <div id="slide-1" className="slide current">
          <div
            className="image"
            style={{ backgroundImage: "url(/images/slider/pc-phone.png)" }}
          ></div>
          <div className="text">
            Получайте <Underlined text="максимум" /> отдачи от каждого SKU
          </div>
          <Arrow />
        </div>
        <div id="slide-2" className="slide">
          <div
            className="image"
            style={{ backgroundImage: "url(/images/slider/phones.png)" }}
          ></div>
          <div className="text">
            Узнавайте результаты <Underlined text={"продаж"} /> в реальном
            времени
          </div>
          <Arrow />
        </div>
        <div id="slide-3" className="slide">
          <div
            className="image"
            style={{ backgroundImage: "url(/images/slider/pad.png)" }}
          ></div>
          <div className="text">
            Формируйте анкеты <Underlined text="под любую задачу" />
          </div>
          <Arrow />
        </div>
      </div>
      <div className="pager-holder">
        <Page index={1} onClick={onClick} currentId={sliderClass} />
        <Page index={2} onClick={onClick} currentId={sliderClass} />
        <Page index={3} onClick={onClick} currentId={sliderClass} />
      </div>
    </div>
  );
}

export default Slider;
