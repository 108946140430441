import Button from "../common/Button";
import Underlined from "../common/Underlined";

function Footer({ onFormSubmit }) {
  return (
    <div className="footer">
      <div className="halfs">
        <div className="left">
          <div className="clients-header">
            <h2 className="hero-header small">
              Uniretail делает управление{" "}
              <Underlined text="продажами" underlineVariant={9} /> проще
            </h2>
          </div>
        </div>
        <div className="right"></div>
      </div>
      <div className="halfs">
        <div className="left">
          <div className="sub-header">
            Принимайте решения, опираясь на аналитику в реальном времени. Ваши
            действия будут базироваться на точных и свежих данных. Узнайте,
            какие возможности дает экосистема URT в вашем сегменте рынка.
          </div>
        </div>
        <div className="right">
          <form onSubmit={onFormSubmit}>
            <div className="inputs">
              <div className="input-holder">
                <input
                  type="text"
                  name="name"
                  placeholder="Имя"
                  className="text-input"
                />
              </div>
              <div className="input-holder">
                <input
                  type="phone"
                  name="phone"
                  placeholder="Телефон"
                  className="text-input"
                />
              </div>
            </div>
            <div className="inputs">
              <div className="input-holder">
                <Button text="заказать звонок" />
              </div>
              <div className="input-holder">
                <div className="small-text">
                  <span>
                    Нажимая на кнопку Заказать звонок», вы принимаете{" "}
                    <a href={"#"}>
                      «Соглашение об обработке персональных данных»
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Footer;
