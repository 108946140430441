import { useMemo } from "react";
import Close from "./Close";

function Card({ header, items, noClose, onClose }) {
  const itemsRender = useMemo(() => {
    return items.map((item) => {
      if (!item.items) {
        return (
          <div key={item} className="item">
            {item}
          </div>
        );
      }
      return (
        <div key={item.name} className="item">
          {item.name}
          {item.items.map((item) => {
            return (
              <div key={item} className="sub-item">
                {item}
              </div>
            );
          })}
        </div>
      );
    });
  }, [items]);

  return (
    <div className="card">
      <h4 className="header">{header}</h4>
      {!noClose && <Close onClick={onClose} />}
      <div className="items-holder">{itemsRender}</div>
    </div>
  );
}

export default Card;
