import Bullit from "../common/Bullit";
import Button from "../common/Button";
import Underlined from "../common/Underlined";

function Pros({ showForm }) {
  return (
    <div className="halfs">
      <div className="left">
        <div
          className="image"
          style={{ backgroundImage: "url(/images/stilus.png)" }}
        ></div>
      </div>
      <div className="right">
        <h2 className="hero-header">
          <Underlined text={"Насколько выше"} underlineVariant={1} /> могут быть
          ваши продажи?
        </h2>
        <div className="sub-header">
          Система URT позволяет контролировать продвижение ваших брендов в
          рознице
        </div>
        <div className="list-item">
          <Bullit />
          <div className="text">С точностью до 1 SKU</div>
        </div>
        <div className="list-item">
          <Bullit />
          <div className="text">С масштабированием на сотни торговых точек</div>
        </div>
        <div className="list-item">
          <Bullit />
          <div className="text">В удобном формате представления данных</div>
        </div>
        <div className="list-item">
          <Bullit />
          <div className="text">
            С гибкой настройкой анкет для сбора полевой информации
          </div>
        </div>
        <div className="action-holder">
          <Button onClick={showForm} text={"Короткое обсуждение"} />
        </div>
      </div>
    </div>
  );
}

export default Pros;
