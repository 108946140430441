import { useMemo } from "react";

function Arrow({ variant }) {
  const arrow = useMemo(() => {
    if (!variant) {
      return (
        <svg
          width="280"
          height="114"
          viewBox="0 0 280 114"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.8964 90.2745C52.6587 87.8326 53.3032 85.7253 53.6378 84.6098C55.6583 77.8172 62.7236 61.5547 63.7834 48.5893C64.5953 38.6979 61.9241 30.6906 52.8074 28.447C44.5212 26.408 36.1544 31.4467 28.742 40.5262C12.7894 60.0673 0.691705 98.0958 0.00376955 110.528C-0.0706019 111.886 0.964394 113.044 2.32167 113.119C3.67275 113.193 4.83166 112.158 4.90603 110.801C5.56918 98.8705 17.2332 62.3914 32.5475 43.6312C38.5344 36.2994 44.9426 31.5706 51.636 33.2192C58.2427 34.8429 59.4761 41.0157 58.8873 48.1864C57.8585 60.8047 50.8986 76.5962 48.9277 83.2091C47.6944 87.3553 42.5442 103.035 42.4264 104.486C42.2777 106.301 43.573 106.909 44.0131 107.076C44.3539 107.206 46.0954 107.745 47.18 105.793C62.6616 77.9659 105.679 45.9801 151.505 33.2316C196.927 20.5946 245.25 26.9472 271.949 75.9889C272.6 77.1789 274.093 77.6188 275.283 76.9743C276.473 76.3235 276.913 74.83 276.263 73.64C248.287 22.2556 197.776 15.2584 150.191 28.4965C110.086 39.6522 72.1626 65.2485 51.8964 90.2745Z"
            fill="#42AA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M272.374 72.4559C267.013 70.262 261.745 67.9069 256.21 66.1282C241.466 61.387 225.142 59.5834 209.84 62.3104C208.501 62.5521 207.615 63.8289 207.85 65.1613C208.092 66.5 209.369 67.3863 210.701 67.1508C225.222 64.554 240.716 66.3078 254.704 70.8073C261.038 72.8463 267.025 75.6538 273.204 78.0895C273.514 78.2072 276.167 79.3477 276.793 79.4345C278.249 79.6452 278.987 78.8023 279.278 78.3685C279.526 78.009 279.737 77.5193 279.755 76.8624C279.768 76.3232 279.551 75.2944 278.968 73.9805C277.115 69.7662 271.382 60.5565 270.601 58.9451C261.646 40.4762 256.415 21.7904 250.416 2.25552C250.013 0.960219 248.637 0.228821 247.342 0.625469C246.046 1.02212 245.321 2.39811 245.718 3.69341C251.791 23.4762 257.115 42.3912 266.182 61.0833C266.715 62.1926 270.006 68.1734 272.374 72.4559Z"
            fill="#42AA3C"
          />
        </svg>
      );
    } else if (variant === 1) {
      return (
        <svg
          width="150"
          height="65"
          viewBox="0 0 150 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M142.791 54.4563C139.338 48.5243 136.001 42.8458 132.688 37.159C132.022 36.0281 131.553 35.2293 130.952 33.674C130.494 32.5236 129.574 30.0631 130.911 29.8328C132.036 29.7579 132.843 29.848 134.192 31.7022C137.65 36.5079 140.365 41.7895 143.088 47.0151C145.489 51.631 147.249 55.1875 149.133 59.3969C150.012 61.3629 149.297 62.5252 147.357 62.9414C145.615 63.3144 143.834 63.7279 142.054 63.766C135.639 63.9385 129.245 63.9429 122.82 64.0117C122.022 64.0252 121.4 64.0837 120.449 63.964C119.296 63.8078 118.449 63.7582 118.374 62.6333C118.293 61.5644 119.738 61.1086 120.511 61.0236C126.954 60.1717 140.275 58.6363 140.965 58.393C137.867 55.9773 135.05 53.6768 132.1 51.5302C111.486 36.6793 89.9879 23.4248 65.6097 15.4428C46.3126 9.124 26.601 5.20044 6.30228 4.32199C5.10388 4.2624 3.89574 4.09914 2.73522 3.91911C1.1589 3.69818 0.37792 3.30405 0.43584 2.48037C0.75321 0.949424 1.86073 0.826839 2.73586 0.652279C4.47775 0.279338 6.32161 0.272091 8.10948 0.2578C32.0264 0.316207 54.869 5.76028 77.0922 14.234C99.9019 22.9295 119.83 36.3623 138.686 51.5385C139.65 52.3768 142.228 54.2262 142.791 54.4563Z"
            fill="#42AA3C"
          />
        </svg>
      );
    }
  }, [variant]);

  return <div className="arrow">{arrow}</div>;
}

export default Arrow;
