import Bullit from "../common/Bullit";
import Button from "../common/Button";
import Underlined from "../common/Underlined";

function Steps({}) {
  return (
    <div className="halfs">
      <div className="left">
        <svg
          width="619"
          height="586"
          viewBox="0 0 619 586"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M549.105 263.807C554.509 270.339 564.963 283.962 565.922 285.313C561.757 290.417 562.379 298.767 567.729 302.86C572.565 306.561 579.991 306.4 584.784 302.741C582.198 300.313 578.931 296.963 576.186 293.752C576.036 293.577 576.117 288.57 576.376 286.042C578.671 285.86 582.113 284.907 584.655 284.875C587.957 288.018 591.268 291.535 593.789 294.113C595.655 291.045 597.591 287.197 596.712 283.449C594.646 274.634 586.104 268.958 578.679 273.751C574.527 269.113 564.195 255.256 562.214 253.098C563.82 250.374 565.739 246.13 565.004 242.92C562.921 233.818 551.462 226.544 543.289 232.707C545.594 236.104 548.333 239.224 550.824 242.486C550.348 243.51 550.111 244.682 549.796 245.759C549.38 247.177 548.688 248.502 548.309 249.931C545.771 250 543.216 250.944 540.702 250.906C538.148 248.026 535.214 243.995 533.298 240.699C525.572 250.369 528.287 266.136 542.893 265.228C544.992 265.097 547.181 264.688 549.105 263.807Z"
            fill="white"
            stroke="#42AA3C"
            strokeWidth="2.29167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154.325 550.126C165.087 550.473 209.216 552.344 217.012 549.736C226.339 546.616 223.919 519.272 223.304 509.109C223.059 505.054 221.421 499.777 217.638 497.509C211.055 493.561 154.078 493.799 148.747 495.449C140.628 497.961 142.232 508.468 142.074 513.838C141.847 521.537 141.731 529.266 141.745 536.969C141.751 540.778 141.438 544.637 144.351 547.517C146.986 550.122 150.961 550.018 154.325 550.126Z"
            fill="white"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M171.834 550.707C178.095 550.888 185.112 551.048 191.755 551.089C192.403 554.89 192.328 558.987 192.268 562.247C192.258 562.822 192.248 563.371 192.242 563.888C189.329 563.877 185.635 563.81 181.737 563.738C178.068 563.671 174.218 563.601 170.668 563.571L170.744 562.437C170.773 561.948 170.8 561.399 170.829 560.801C170.965 558.054 171.149 554.291 171.834 550.707Z"
            fill="white"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M161.539 563.566C164.495 563.5 167.556 563.487 170.667 563.504M204.946 563.963C200.999 564.009 196.699 563.959 192.227 563.878"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M157.664 526.626C163.039 522.257 167.403 516.546 173.083 512.513C175.392 515.595 178.144 518.363 180.152 521.677C183.422 520.36 198.097 508.522 201.449 505.614"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M161.59 531.885C161.564 533.952 161.439 536.021 161.478 538.088"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M170.681 525.7C170.356 529.887 170.54 534.081 170.637 538.27"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M179.657 530.83C179.636 533.538 179.74 536.285 179.853 538.994"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M190.313 525.887C190.824 530.11 190.504 534.356 190.763 538.58"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M200.089 538.876C199.715 536.015 200.182 532.932 200.204 530.057C200.242 525.332 199.863 520.503 200.246 515.795"
            stroke="#42AA3C"
            strokeWidth="2.20833"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M467.414 21.0216C454.365 18.7852 436.984 28.1129 435.817 55.9728C435.533 62.7567 426.548 66.3447 427.823 73.0765C431.108 90.4327 469.459 90.6624 481.707 87.1625C486.291 85.8525 493.141 83.7138 495.046 78.6947C497.298 72.7624 489.059 67.3634 489.462 61.2337C490.524 45.1133 488.869 24.6987 467.414 21.0216V21.0216Z"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M490.002 78.7084C490.509 77.1661 489.047 76.3041 487.848 75.7056C485.014 74.2901 481.957 73.6477 478.874 73.0257C467.911 70.813 455.96 68.7313 444.756 69.9692C441.063 70.3775 431.57 71.681 435.825 77.2923"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M483.752 61.4249C477.246 57.618 470.04 55.1716 462.536 54.4684C458.801 54.1183 454.978 54.5198 451.255 54.7945C445.359 55.2301 439.476 56.5204 434.606 60.003"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M467.714 70.9989C467.418 73.1935 466.529 76.3729 464.848 77.8527C461.676 80.6455 456.451 80.7868 454.147 76.868C453.598 75.9351 453.321 74.8248 453.143 73.7706C452.943 72.588 452.967 70.785 453.281 69.6294C458.184 69.6719 463.265 70.3028 467.714 70.9989Z"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M472.023 22.1368C468.816 21.1879 465.905 20.5255 462.283 20.7478C462.324 19.1254 462.705 17.6267 463.081 15.8472C464.028 11.3638 468.981 8.08156 471.841 12.7629C473.303 15.1559 472.474 19.5039 472.023 22.1368Z"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M417.164 50.4453C419.552 50.5844 421.977 50.6932 424.349 51.0122"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M411.898 30.3379C416.831 33.2938 421.904 35.9254 427.011 38.559"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M427.451 22.9297C428.983 24.7693 430.519 26.6048 432.059 28.4381"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M494.653 39.1192C496.316 38.4601 497.979 37.7928 499.632 37.1109"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M495.348 47.5029C500.178 46.5186 505.053 45.9423 509.97 45.6548"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M496.05 55.1992C497.905 56.6048 499.673 58.1005 501.494 59.5474"
            stroke="#42AA3C"
            strokeWidth="2.80764"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.6343 357.847C80.8305 358.946 85.3726 353.293 87.671 349.815C94.5622 339.387 99.7424 328.675 106.903 316.63C109.89 311.607 114.155 303.706 111.463 297.27C109.776 293.239 100.361 291.489 96.6627 290.943C88.7898 289.781 77.8753 287.218 70.463 290.932C67.7269 292.303 65.8065 295.902 63.8885 298.159C56.0249 307.409 50.0494 318.549 43.8563 328.937C40.3478 334.823 36.2531 339.837 40.4589 346.59C42.182 349.357 47.601 351.143 50.4273 352.107C58.3569 354.81 66.3621 356.38 74.6343 357.847Z"
            fill="white"
            stroke="#42AA3C"
            strokeWidth="2.10417"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.3916 337.448C40.4171 341.914 44.9345 341.97 48.0865 343.223C56.8392 346.702 65.2133 348.524 74.5423 349.788C76.0867 349.997 77.5833 350.206 78.767 349.46C81.1932 347.931 82.9093 345.095 84.2646 342.632C90.6369 331.05 98.4972 320.309 104.476 308.495C106.025 305.435 107.716 302.343 108.881 299.108C109.403 297.656 109.406 295.793 107.967 294.854"
            stroke="#42AA3C"
            strokeWidth="2.10417"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M82.3842 289.047C82.2417 289.859 81.8194 292.198 82.4058 292.923C83.1217 293.809 84.7891 293.732 85.8064 293.872C88.1781 294.2 90.4743 294.889 92.8645 295.103C93.7723 295.185 94.7804 295.417 95.661 295.077C97.1552 294.498 98.1893 292.581 99.2635 291.43"
            stroke="#42AA3C"
            strokeWidth="2.10417"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M270.782 188.363C268.022 186.383 264.942 184.696 262.045 182.931C260.278 181.854 258.591 180.054 256.717 179.167C249.653 191.609 242.435 204.059 236.586 217.078C240.961 215.515 246.382 213.505 250.149 210.898C251.022 215.667 252.866 220.155 254.021 224.832C258.733 212.059 266.238 200.418 271.126 187.716"
            fill="white"
          />
          <path
            d="M270.782 188.363C268.022 186.383 264.942 184.696 262.045 182.931C260.278 181.854 258.591 180.054 256.717 179.167C249.653 191.609 242.435 204.059 236.586 217.078C240.961 215.515 246.382 213.505 250.149 210.898C251.022 215.667 252.866 220.155 254.021 224.832C258.733 212.059 266.238 200.418 271.126 187.716"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M295.018 181.652C291.566 183.852 285.931 187.245 282.276 189.115C284.059 195.326 294.287 218.867 297.204 223.902C298.179 218.691 299.14 213.395 300.268 208.212C305.421 210.826 309.985 212.762 315.538 216.243C314.306 213.712 298.791 187.488 295.018 181.652Z"
            fill="white"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M292.357 140.854C290.171 140.74 287.938 141.514 286.44 143.212C287.769 140.063 285.019 135.737 282.335 134.266C277.665 131.705 269.953 135.053 269.412 140.496C265.108 132.357 253.216 136.288 253.706 145.321C252.976 144.866 252.268 144.581 251.441 144.302C243.834 141.733 239.233 152.947 244.229 158.031C237.647 159.891 235.119 171.276 242.462 173.908C234.665 178.119 239.763 192.96 248.916 189.993C248.309 192.566 248.182 195.736 249.63 198.072C251.979 201.861 258.034 203.597 261.696 200.51C261.635 208.365 273.401 209.516 276.628 203.269C282.092 210.227 292.582 207.545 293.352 198.435C295.173 199.082 297.242 199.642 299.167 199.152C305.15 197.626 307.811 190.429 304.775 185.357C311.794 184.33 316.051 172.089 305.966 167.626C309.671 164.574 310.198 159.366 306.86 154.661C305.099 152.177 301.017 151.673 298.576 152.079C299.655 149.349 299.758 146.301 298 143.823"
            fill="white"
          />
          <path
            d="M292.357 140.854C290.171 140.74 287.938 141.514 286.44 143.212C287.769 140.063 285.019 135.737 282.335 134.266C277.665 131.705 269.953 135.053 269.412 140.496C265.108 132.357 253.216 136.288 253.706 145.321C252.976 144.866 252.268 144.581 251.441 144.302C243.834 141.733 239.233 152.947 244.229 158.031C237.647 159.891 235.119 171.276 242.462 173.908C234.665 178.119 239.763 192.96 248.916 189.993C248.309 192.566 248.182 195.736 249.63 198.072C251.979 201.861 258.034 203.597 261.696 200.51C261.635 208.365 273.401 209.516 276.628 203.269C282.092 210.227 292.582 207.545 293.352 198.435C295.173 199.082 297.242 199.642 299.167 199.152C305.15 197.626 307.811 190.429 304.775 185.357C311.794 184.33 316.051 172.089 305.966 167.626C309.671 164.574 310.198 159.366 306.86 154.661C305.099 152.177 301.017 151.673 298.576 152.079C299.655 149.349 299.758 146.301 298 143.823"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M281.025 147.694C270.194 142.646 255.245 149.585 251.351 160.839C246.795 174.009 250.09 187.735 263.606 194.088C273.596 198.785 286.434 197.223 294.275 189.239C306.271 177.023 302.191 157.802 287.675 150.628"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M267.188 166.231C270.399 163.765 273.302 161.007 274.82 157.545"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M275.022 157.967C274.786 165.714 275.417 173.473 275.104 181.211L275.022 157.967Z"
            fill="white"
          />
          <path
            d="M275.022 157.967C274.786 165.714 275.417 173.473 275.104 181.211"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266.631 181.864C272.407 181.173 278.347 181.667 284.076 180.929L266.631 181.864Z"
            fill="white"
          />
          <path
            d="M266.631 181.864C272.407 181.173 278.347 181.667 284.076 180.929"
            stroke="#42AA3C"
            strokeWidth="2.54167"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M351.788 441.879C338.862 439.891 317.724 435.883 305.86 433.252C316.841 419.132 325.206 412.458 337.818 398.635"
            stroke="#42AA3C"
            strokeWidth="3.50619"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M339.378 383.741C334.992 383.437 332.321 387.83 331.468 391.608C329.865 398.706 333.62 408.629 342.221 408.251C347.144 408.034 351.331 406.124 354.013 401.871C359.506 393.159 353.114 381.706 342.937 382.425"
            stroke="#42AA3C"
            strokeWidth="3.50619"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M353.202 430.521C342.339 432.07 341.04 452.758 352.848 453.915C356.785 454.301 362.323 453.532 364.941 450.262C370.148 443.761 366.595 433.34 358.782 431.824"
            stroke="#42AA3C"
            strokeWidth="3.50619"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M308.187 421.477C304.698 420.601 300.163 420.418 297.135 422.745C291.108 427.375 290.068 441.107 297.251 445.063C311.668 453.004 322.021 433.068 310.7 423.086"
            stroke="#42AA3C"
            strokeWidth="3.50619"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g clipPath="url(#clip0_842_2)">
            <path
              d="M484.999 205.001C516.217 167.215 501.249 187.401 529.206 149.878M529.206 149.878C534.778 142.401 514.769 144.577 512.366 144.746C492.582 146.163 477.059 155.593 452.499 164M529.206 149.878C453.414 199.284 374.953 256.064 296.127 314.193C220.067 370.281 143.668 427.625 68.999 480.81"
              stroke="#42AA3C"
              strokeWidth="2.93433"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_842_2">
              <rect
                width="375.188"
                height="533.469"
                fill="white"
                transform="translate(106.946 513.688) rotate(-106.562)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="right">
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">
            Запуск за 3 дня без участия IT-персонала и сложной процедуры
            внедрения
          </div>
        </div>
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">
            Возможность быстрой доводки под ваши задачи
          </div>
        </div>
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">
            Настройка под специфику бизнес-процессов компании
          </div>
        </div>
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">Интеграция с ведущими учетными системами</div>
        </div>
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">Интеграция с ведущими учетными системами</div>
        </div>
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">
            Понятный принцип работы на любом устройстве
          </div>
        </div>
        <div className="list-item">
          <Bullit variant={1} />
          <div className="text">
            Доступ к данным без постоянного подключения к интернету
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;
