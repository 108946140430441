import React, { useCallback, useMemo, useState } from "react";

import "./App.scss";
import Header from "./modules/header/Header";
import Slider from "./modules/carousel/Slider";
import Pros from "./modules/pros/Pros";
import Improve from "./modules/improve/Improve";
import Steps from "./modules/steps/Steps";
import Ecosystem from "./modules/ecosystem/Ecosystem";
import Local from "./modules/local/Local";
import OneClick from "./modules/oneClick/OneClick";
import Control from "./modules/Control/Control";
import Sfe from "./modules/SFE/SFE";
import Clients from "./modules/Clients/Clients";
import Daily from "./modules/daily/Daily";
import Footer from "./modules/footer/Footer";
import { Dialog } from "@mui/material";
import Button from "./modules/common/Button";

function App() {
  const [formVisible, setFormVisible] = useState(false);
  const [successFormVisible, setSuccessFormVisible] = useState(false);
  const [errorFormVisible, setErrorFormVisible] = useState(false);

  const hideForm = useCallback(() => {
    setFormVisible(false);
  }, [setFormVisible]);

  const successForm = useCallback(() => {
    hideForm();
    setSuccessFormVisible(true);
  }, [setSuccessFormVisible]);

  const errorForm = useCallback(() => {
    hideForm();
    setErrorFormVisible(true);
  }, [setErrorFormVisible]);

  const hideSuccessForm = useCallback(() => {
    hideForm();
    setSuccessFormVisible(false);
  }, [setSuccessFormVisible]);

  const hideErrorForm = useCallback(() => {
    hideForm();
    setErrorFormVisible(false);
  }, [setErrorFormVisible]);

  const showForm = useCallback(() => {
    setFormVisible(true);
  }, [setFormVisible]);

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      try {
        const result = await fetch("/callback/index.php", {
          method: "post",
          body: data,
        });

        if (!result.ok) {
          throw new Error("Error with request, try again later");
        }
        successForm();
      } catch (error) {
        errorForm();
      }
      return false;
    },
    [successForm, hideForm]
  );

  return (
    <div className="urt">
      <Header showForm={showForm} />
      <Slider />
      <Pros showForm={showForm} />
      <Improve />
      <Steps />
      <Ecosystem />
      <Local />
      <OneClick />
      <Control />
      <Sfe />
      <Clients />
      <Daily />
      <Footer onFormSubmit={onFormSubmit} />
      <Dialog
        sx={{
          ".MuiPaper-root": {
            width: "18rem",
            padding: "2rem",
          },
        }}
        open={formVisible}
        onClose={hideForm}
      >
        <form onSubmit={onFormSubmit}>
          <div className="inputs">
            <div className="input-holder">
              <input
                type="text"
                name="name"
                placeholder="Имя"
                className="text-input"
              />
            </div>
            <div className="input-holder">
              <input
                type="phone"
                name="phone"
                placeholder="Телефон"
                className="text-input"
              />
            </div>
          </div>
          <div className="inputs">
            <div className="input-holder">
              <Button text="заказать звонок" />
            </div>
            <div className="input-holder">
              <div className="small-text">
                <span>
                  Нажимая на кнопку Заказать звонок», вы принимаете{" "}
                  <a href={"#"}>
                    «Соглашение об обработке персональных данных»
                  </a>
                </span>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
      <Dialog
        sx={{
          ".MuiPaper-root": {
            width: "18rem",
            padding: "2rem",
          },
        }}
        open={successFormVisible}
        onClose={hideSuccessForm}
      >
        <div className="inputs">
          <div className="input-holder">
            <div className="small-text">
              <span>
                Ваша заявка успешно отправлена, наш менеджер свяжется с вами в
                ближайшее время.
              </span>
            </div>
          </div>
          <div className="input-holder">
            <Button onClick={hideSuccessForm} text="Закрыть" />
          </div>
        </div>
      </Dialog>
      <Dialog
      sx={{
        ".MuiPaper-root": {
          width: "18rem",
          padding: "2rem",
        },
      }}
      open={errorFormVisible}
      onClose={hideErrorForm}
    >
      <div className="inputs">
        <div className="input-holder">
          <div className="small-text">
            <span>
              Не удалось отправить заявку, попробуйте повторить отправку позднее.
            </span>
          </div>
        </div>
        <div className="input-holder">
          <Button onClick={hideErrorForm} text="Закрыть" />
        </div>
      </div>
    </Dialog>
    </div>
  );
}

export default App;
