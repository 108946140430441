import Underlined from "../common/Underlined";

function Clients({}) {
  return (
    <div className="clients">
      <div className="clients-header">
        <h2 className="hero-header small">Клиенты Uniretail</h2>
        <div className="sub-header padded">
          оценивают прирост товарооборота и отдачи
          <br /> от коммерческой команды в 40%
        </div>
      </div>
      <div className="small-cards">
        <div className="small-card">
          <div className="icon">
            <svg
              width="46"
              height="45"
              viewBox="0 0 46 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9999 42.2219C26.3101 42.2219 31.9725 38.2218 31.9725 22.2213C31.9725 6.22082 26.3101 2.2207 22.9999 2.2207C19.6897 2.2207 14.0283 6.49036 14.0283 22.2213C14.0283 37.9523 19.6897 42.2219 22.9999 42.2219Z"
                stroke="#42AA3C"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.57509 32.6968C5.23021 35.375 11.9463 37.8598 27.4859 29.4786C43.0255 21.0974 44.0803 14.422 42.4241 11.7447C40.769 9.0656 33.7918 6.72172 18.5143 14.962C3.23584 23.2022 1.91997 30.0186 3.57509 32.6968Z"
                stroke="#42AA3C"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.57527 11.7439C1.92015 14.4221 2.97387 21.0975 18.5145 29.4787C34.0541 37.8599 40.7692 35.3751 42.4243 32.6979C44.0794 30.0188 42.7636 23.2024 27.4861 14.9621C12.2076 6.72186 5.23039 9.06574 3.57527 11.7439Z"
                stroke="#42AA3C"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="text">
            Рабочая нагрузка персонала оптимизируется в среднем на 15%.
          </div>
        </div>
        <div className="small-card">
          <div className="icon">
            <svg
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.98 0.220703C8.94 0.220703 0 9.18097 0 20.2213C0 31.2616 8.94 40.2219 19.98 40.2219C31.04 40.2219 40 31.2616 40 20.2213C40 9.18097 31.04 0.220703 19.98 0.220703ZM20 36.2218C11.16 36.2218 4 29.0616 4 20.2213C4 11.381 11.16 4.22082 20 4.22082C28.84 4.22082 36 11.381 36 20.2213C36 29.0616 28.84 36.2218 20 36.2218ZM19.56 10.221H19.44C18.64 10.221 18 10.861 18 11.661V21.1013C18 21.8014 18.36 22.4614 18.98 22.8214L27.28 27.8015C27.96 28.2015 28.84 28.0015 29.24 27.3215C29.3404 27.1596 29.4072 26.9791 29.4364 26.7908C29.4657 26.6024 29.4567 26.4102 29.41 26.2254C29.3633 26.0407 29.28 25.8672 29.1649 25.7153C29.0498 25.5635 28.9053 25.4363 28.74 25.3415L21 20.7413V11.661C21 10.861 20.36 10.221 19.56 10.221Z"
                fill="#42AA3C"
              />
            </svg>
          </div>
          <div className="text">
            Время, которое сотрудники тратили на заполнение бумаг в офисе,
            сокращается с 40% до 10%.
          </div>
        </div>
        <div className="small-card">
          <div className="icon">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.84 8.66097C30.0011 9.82175 30.9221 11.1999 31.5505 12.7167C32.1789 14.2335 32.5023 15.8592 32.5023 17.501C32.5023 19.1428 32.1789 20.7685 31.5505 22.2853C30.9221 23.8021 30.0011 25.1802 28.84 26.341L20 35.176L11.16 26.3385C9.99912 25.1776 9.07826 23.7994 8.44999 22.2826C7.82172 20.7659 7.49836 19.1402 7.49836 17.4985C7.49836 15.8567 7.82172 14.2311 8.44999 12.7143C9.07826 11.1975 9.99912 9.81936 11.16 8.65847C12.3209 7.49758 13.6991 6.57672 15.2158 5.94845C16.7326 5.32019 18.3583 4.99682 20 4.99682C21.6417 4.99682 23.2674 5.32019 24.7842 5.94845C26.3009 6.57672 27.6791 7.49759 28.84 8.65847V8.66097ZM31.49 28.991C33.7627 26.7184 35.3105 23.823 35.9377 20.6707C36.5648 17.5185 36.2431 14.2511 35.0132 11.2817C33.7833 8.31233 31.7005 5.77435 29.0282 3.98871C26.3558 2.20308 23.214 1.25 20 1.25C16.786 1.25 13.6442 2.20308 10.9718 3.98871C8.29952 5.77435 6.21672 8.31233 4.98683 11.2817C3.75695 14.2511 3.43523 17.5185 4.06235 20.6707C4.68947 23.823 6.23727 26.7184 8.51001 28.991L17.3475 37.831C17.6958 38.1794 18.1093 38.4559 18.5644 38.6445C19.0195 38.8331 19.5073 38.9302 20 38.9302C20.4927 38.9302 20.9805 38.8331 21.4356 38.6445C21.8908 38.4559 22.3043 38.1794 22.6525 37.831L31.49 28.991ZM20 22.501C21.3261 22.501 22.5979 21.9742 23.5355 21.0365C24.4732 20.0988 25 18.8271 25 17.501C25 16.1749 24.4732 14.9031 23.5355 13.9654C22.5979 13.0278 21.3261 12.501 20 12.501C18.6739 12.501 17.4022 13.0278 16.4645 13.9654C15.5268 14.9031 15 16.1749 15 17.501C15 18.8271 15.5268 20.0988 16.4645 21.0365C17.4022 21.9742 18.6739 22.501 20 22.501Z"
                fill="#42AA3C"
              />
            </svg>
          </div>
          <div className="text">
            Количество посещаемых локаций растет на 30% в месяц.
          </div>
        </div>
      </div>
      <div className="hero-header smallest center">
        Cреди клиентов, которые постоянно используют{" "}
        <Underlined text="систему URT" underlineVariant={8} />
      </div>
      <div className="logos">
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/benefit.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/dior.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/kenzo.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/givenchy.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/guerlain.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/mybi.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/makeup_forever.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/acquadiparma.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/loewe.png)" }}
        ></div>
        <div
          className="logo"
          style={{ backgroundImage: "url(/images/logos/loyalme.png)" }}
        ></div>
      </div>
    </div>
  );
}

export default Clients;
