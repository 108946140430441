function Page({ index, onClick, currentId }) {
  const id = `page-${index}`;
  return (
    <div
      onClick={onClick}
      id={id}
      className={`page ${id === currentId ? "current" : ""}`}
    ></div>
  );
}

export default Page;
