import Underlined from "../common/Underlined";

function Ecosystem({}) {
  return (
    <div className="halfs">
      <div className="left">
        <h2 className="hero-header">
          Экосистема, в которой{" "}
          <Underlined text={"все данные работают"} underlineVariant={3} />
        </h2>
        <div className="sub-header">
          URT (Universal Retail Tool) позволяет полевым сотрудникам быстро
          накапливать данные, а бренд-менеджерам - анализировать ее при помощи
          наглядных таблиц и графиков.
        </div>
      </div>
      <div className="right">
        <div
          className="image"
          style={{ backgroundImage: "url(/images/scheme.png)" }}
        ></div>
      </div>
    </div>
  );
}

export default Ecosystem;
