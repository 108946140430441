import Call from "@mui/icons-material/Call";

function Header({ showForm }) {
  return (
    <div className="header">
      <div className="logo">
        <svg
          width="84"
          height="69"
          viewBox="0 0 84 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.3879 57.8495L3.83128 41.8943C2.81863 41.5158 1.92958 40.866 1.26131 40.0162C0.593039 39.1663 0.171288 38.1491 0.042189 37.0757C-0.0869095 36.0023 0.081614 34.914 0.52932 33.9299C0.977026 32.9458 1.68669 32.1037 2.5807 31.4958L45.6376 2.01525C46.5278 1.4077 47.5675 1.0558 48.6437 0.997801C49.7199 0.939798 50.7913 1.178 51.7417 1.68635C52.692 2.1947 53.4848 2.95381 54.034 3.88123C54.5831 4.80864 54.8676 5.8688 54.8564 6.94655L54.3561 52.4001C54.3448 53.3471 54.1056 54.2774 53.6587 55.1123C53.2119 55.9473 52.5705 56.6623 51.7888 57.1969C51.0072 57.7314 50.1083 58.0698 49.1682 58.1834C48.2281 58.2971 47.2744 58.1825 46.3879 57.8495Z"
            fill="#FFED00"
          />
          <path
            opacity="0.29"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.4641 67.854L12.9621 53.3995C11.9507 53.0392 11.0569 52.4095 10.3771 51.5784C9.6973 50.7473 9.25741 49.7463 9.10479 48.6835C8.95218 47.6207 9.09264 46.5364 9.51109 45.5475C9.92954 44.5587 10.61 43.7029 11.4792 43.0725L50.7842 14.4853C51.6512 13.8519 52.6752 13.468 53.7449 13.3754C54.8146 13.2828 55.8893 13.485 56.8522 13.96C57.8152 14.4351 58.6296 15.1648 59.2071 16.0701C59.7846 16.9753 60.1031 18.0215 60.1281 19.0951L61.3073 62.083C61.341 63.0419 61.1401 63.9945 60.7222 64.8582C60.3043 65.7219 59.682 66.4705 58.9092 67.0391C58.1364 67.6078 57.2365 67.9793 56.2876 68.1213C55.3387 68.2632 54.3695 68.1715 53.4641 67.854Z"
            fill="#FFED00"
          />
          <path
            d="M32.1789 26.2586V36.3596C32.1789 36.5884 32.2436 36.7724 32.3729 36.9116C32.5121 37.041 32.6961 37.1056 32.9249 37.1056H33.5814C33.8102 37.1056 33.9892 37.041 34.1185 36.9116C34.2578 36.7724 34.3274 36.5884 34.3274 36.3596V26.2586H36.3566V36.8967C36.3566 37.5731 36.1527 38.1152 35.7448 38.523C35.337 38.9309 34.7949 39.1348 34.1185 39.1348H32.3878C31.7114 39.1348 31.1693 38.9309 30.7615 38.523C30.3537 38.1152 30.1498 37.5731 30.1498 36.8967V26.2586H32.1789ZM37.5283 39.1348V26.2437H38.4384C38.7965 26.2437 39.1148 26.3481 39.3933 26.557C39.6818 26.7559 39.8807 27.0295 39.9901 27.3776L41.9148 33.659L41.8402 26.2437H43.8694V39.1348H42.9592C42.6012 39.1348 42.2779 39.0353 41.9894 38.8364C41.7109 38.6275 41.517 38.349 41.4075 38.0008L39.4828 31.7194L39.5574 39.1348H37.5283ZM44.9115 39.1348V26.2437H46.9406V39.1348H44.9115Z"
            fill="#3AAA35"
          />
          <path
            d="M47.9142 26.2586H52.0769C52.6638 26.2586 53.1313 26.4376 53.4794 26.7957C53.8375 27.1439 54.0165 27.6114 54.0165 28.1982V31.7492C54.0165 32.1969 53.9121 32.5798 53.7032 32.8981C53.4943 33.2065 53.2059 33.4253 52.8378 33.5546L54.4642 39.1198H53.0169C52.7881 39.1198 52.5842 39.0552 52.4052 38.9259C52.2361 38.7966 52.1217 38.6225 52.062 38.4037L50.6893 33.6889H49.9433V39.1198H47.9142V26.2586ZM49.9433 31.6746H51.2414C51.4702 31.6746 51.6492 31.61 51.7785 31.4807C51.9178 31.3414 51.9874 31.1574 51.9874 30.9286V29.0188C51.9874 28.7901 51.9178 28.611 51.7785 28.4817C51.6492 28.3425 51.4702 28.2728 51.2414 28.2728H49.9433V31.6746ZM55.0654 26.2437H60.6456V28.2728H57.0945V31.6746H59.8249V32.5102C59.8249 32.8683 59.7155 33.1567 59.4967 33.3756C59.2779 33.5944 58.9894 33.7038 58.6313 33.7038H57.0945V37.1056H60.6456V39.1348H55.0654V26.2437ZM61.2806 26.2586H67.6366V28.2728H65.4732V39.1348H63.444V28.2728H61.2806V26.2586ZM68.5792 27.8551C68.6587 27.3677 68.8676 26.9748 69.2058 26.6764C69.554 26.378 69.9767 26.2288 70.474 26.2288H71.9362L73.9654 39.1348H71.8616L71.5185 36.658H69.2655L68.9223 39.1198H66.8186L68.5792 27.8551ZM69.549 34.6438H71.235L70.3994 28.6906L69.549 34.6438ZM74.6529 39.1348V26.2437H76.682V39.1348H74.6529ZM83.1565 39.1348H77.7255V26.2437H79.7547V37.1056H82.8581L83.1565 39.1348Z"
            fill="#3C3C3B"
          />
        </svg>
      </div>
      <div className="phone">
        <a href="tel:+78007499999" className="phone-link">
          +7 800 749 99 99
        </a>
        <div onClick={showForm} className="arrange-call">
          <Call />
          <div className="text">заказать звонок</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
