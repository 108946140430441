import Bullit from "../common/Bullit";
import Underlined from "../common/Underlined";

function Control({}) {
  return (
    <div className="one-click">
      <h2 className="hero-header center">
        URT Manager: <Underlined text={"100% контроль"} underlineVariant={6} />{" "}
        над продажами на одном экране
      </h2>
      <div className="sub-header center">
        Гибко настраиваемая облачная программа для мониторинга и аналитики
        данных
      </div>
      <div className="halfs">
        <div className="left">
          <div
            className="image"
            style={{ backgroundImage: "url(/images/pc.png)" }}
          ></div>
        </div>
        <div className="right">
          <div className="plate">
            <svg
              width="591"
              height="151"
              viewBox="0 0 591 151"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_672_236"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="591"
                height="151"
              >
                <rect width="591" height="151" rx="10" fill="#FAFAFA" />
              </mask>
              <g mask="url(#mask0_672_236)">
                <mask
                  id="mask1_672_236"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="-60"
                  width="597"
                  height="267"
                >
                  <rect
                    y="-60"
                    width="597"
                    height="266.848"
                    rx="12.7679"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask1_672_236)">
                  <path
                    d="M629.503 156.509L616.292 251.823L533.838 185.383L629.503 156.509Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M644.411 46.0586C646.547 45.4103 648.647 47.1025 648.349 49.2311L636.326 134.889C636.003 137.191 633.161 138.259 631.315 136.773L557.39 77.2051C555.545 75.7183 556.154 72.8498 558.464 72.1486L644.411 46.0586Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M542.246 175.352C537.269 176.854 532.382 172.916 533.069 167.957L543.085 95.6924C543.831 90.3149 550.47 87.8127 554.779 91.2852L617.294 141.658C621.604 145.131 620.175 151.831 614.777 153.46L542.246 175.352Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M423.99 213.172C421.854 213.82 419.754 212.128 420.052 209.999L432.075 124.342C432.398 122.04 435.24 120.971 437.086 122.458L511.011 182.025C512.856 183.512 512.247 186.381 509.937 187.082L423.99 213.172Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M226.946 177.872C221.966 179.389 217.063 175.449 217.751 170.483L227.763 98.249C228.508 92.8715 235.147 90.3694 239.457 93.8418L301.762 144.046C306.065 147.513 304.648 154.202 299.264 155.843L226.946 177.872Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M455.928 99.0086C448.698 101.34 441.504 95.5437 442.656 88.3144L470.463 -86.1892C471.67 -93.7659 481.052 -97.2011 487.14 -92.2955L633.858 25.9264C639.946 30.8319 638.03 40.2833 630.452 42.7269L455.928 99.0086Z"
                    fill="#79BBFF"
                  />
                  <path
                    d="M427.051 15.0837C434.283 12.7419 441.487 18.5396 440.335 25.7741L412.531 200.256C411.324 207.833 401.942 211.268 395.854 206.362L249.357 88.3183C243.274 83.4163 245.182 73.9725 252.751 71.5217L427.051 15.0837Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M533.105 81.9723C535.239 81.3263 537.337 83.0163 537.04 85.1431L532.814 115.443C532.492 117.746 529.648 118.817 527.802 117.329L501.622 96.2334C499.776 94.7459 500.387 91.8758 502.698 91.1762L533.105 81.9723Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M523.458 141.302C525.599 140.645 527.709 142.345 527.403 144.481L523.021 174.996C522.691 177.294 519.853 178.356 518.011 176.872L491.791 155.744C489.949 154.26 490.552 151.397 492.856 150.69L523.458 141.302Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M476.926 103.81C479.068 103.153 481.178 104.853 480.871 106.989L476.489 137.504C476.159 139.801 473.322 140.864 471.48 139.379L445.26 118.252C443.418 116.768 444.021 113.905 446.325 113.198L476.926 103.81Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M488.166 138.56C486.035 139.191 483.954 137.502 484.249 135.383L488.48 105.052C488.801 102.749 491.645 101.679 493.491 103.166L519.881 124.43C521.733 125.923 521.11 128.805 518.786 129.493L488.166 138.56Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M407.483 11.4579C405.345 12.0997 403.251 10.4013 403.557 8.27364L407.943 -22.2736C408.273 -24.5707 411.111 -25.6328 412.953 -24.1487L439.382 -2.8525C441.231 -1.363 440.615 1.51146 438.299 2.20682L407.483 11.4579Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M210.118 83.9751C215.098 82.458 220.001 86.3983 219.313 91.3643L209.301 163.598C208.556 168.976 201.916 171.478 197.607 168.006L135.302 117.801C130.999 114.334 132.415 107.645 137.8 106.005L210.118 83.9751Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M30.8318 -67.2857C32.9646 -67.9294 35.0594 -66.2414 34.7649 -64.1165L22.9229 21.3193C22.6034 23.624 19.7581 24.6963 17.9112 23.2081L-55.9976 -36.346C-57.8445 -37.8342 -57.2322 -40.7058 -54.919 -41.4039L30.8318 -67.2857Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M107.973 213.242C105.837 213.887 103.74 212.193 104.041 210.065L116.097 124.817C116.423 122.517 119.264 121.45 121.108 122.936L194.789 182.307C196.636 183.795 196.024 186.666 193.71 187.365L107.973 213.242Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M245.113 59.5651C240.13 61.0779 235.23 57.1295 235.927 52.1626L246.1 -20.3189C246.853 -25.6902 253.486 -28.1841 257.792 -24.7149L320.346 25.6898C324.651 29.159 323.23 35.8523 317.84 37.4883L245.113 59.5651Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M27.9469 134.833C20.7115 137.166 13.5148 131.36 14.6769 124.127L42.6828 -50.1919C43.8989 -57.7619 53.2745 -61.1884 59.3581 -56.2863L205.855 61.7575C211.943 66.6631 210.027 76.1144 202.449 78.5581L27.9469 134.833Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M10.3554 47.5205C12.5245 46.821 14.6825 48.56 14.337 50.7288L-17.1625 248.407C-17.5247 250.68 -20.3393 251.71 -22.1657 250.238L-188.368 116.317C-190.194 114.845 -189.619 112.01 -187.346 111.276L10.3554 47.5205Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M337.232 -12.2758C339.366 -12.9218 341.464 -11.2317 341.167 -9.10493L336.941 21.1947C336.619 23.498 333.775 24.5686 331.929 23.0811L305.749 1.98534C303.903 0.497843 304.514 -2.37223 306.825 -3.07189L337.232 -12.2758Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M105.181 118.19C107.312 117.559 109.393 119.248 109.098 121.367L104.867 151.698C104.546 154.001 101.702 155.071 99.856 153.584L73.4663 132.32C71.6137 130.827 72.2367 127.945 74.5609 127.257L105.181 118.19Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M48.8023 139.83C50.9287 139.186 53.0216 140.862 52.7399 142.982L48.6913 173.46C48.384 175.773 45.5302 176.858 43.6775 175.365L17.2879 154.101C15.4418 152.613 16.0526 149.743 18.3641 149.043L48.8023 139.83Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M235.043 -31.7935C237.177 -32.4481 239.282 -30.7624 238.991 -28.633L234.786 2.06103C234.47 4.36815 231.622 5.44383 229.774 3.95433L203.345 -17.3418C201.503 -18.826 202.106 -21.6889 204.41 -22.3956L235.043 -31.7935Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M225.267 26.2572C227.409 25.6002 229.519 27.3004 229.212 29.4359L224.83 59.9516C224.5 62.2486 221.662 63.3108 219.82 61.8266L193.601 40.6992C191.759 39.215 192.362 36.3522 194.665 35.6455L225.267 26.2572Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M179.458 -10.6529C181.599 -11.3099 183.709 -9.60979 183.403 -7.47421L179.021 23.0414C178.691 25.3385 175.853 26.4007 174.011 24.9165L147.791 3.78907C145.949 2.30488 146.552 -0.558002 148.856 -1.2647L179.458 -10.6529Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M60.2288 174.779C58.0944 175.425 55.997 173.735 56.2937 171.608L60.5198 141.308C60.8411 139.005 63.6851 137.934 65.5312 139.422L91.7118 160.518C93.5578 162.005 92.947 164.875 90.6356 165.575L60.2288 174.779Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M191.757 27.5985C189.619 28.2403 187.525 26.5419 187.83 24.4142L192.217 -6.13295C192.547 -8.43002 195.384 -9.49222 197.226 -8.00803L223.656 13.2881C225.504 14.7776 224.889 17.6521 222.573 18.3474L191.757 27.5985Z"
                    fill="#2A86E5"
                  />
                  <path
                    d="M-20.3165 47.4844C-22.4429 48.1281 -24.5358 46.4525 -24.2541 44.3319L-20.2055 13.8543C-19.8982 11.541 -17.0443 10.4568 -15.1917 11.9496L11.198 33.2138C13.044 34.7013 12.4332 37.5714 10.1218 38.2711L-20.3165 47.4844Z"
                    fill="#2A86E5"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="list-item">
            <Bullit variant={1} />
            <div className="text">
              Визуальное отображение результатов аналитики в реальном времени
            </div>
          </div>
          <div className="list-item">
            <Bullit variant={1} />
            <div className="text">
              Наблюдение онлайн за работой сотрудников с помощью обновляемой
              ленты
            </div>
          </div>
          <div className="list-item">
            <Bullit variant={1} />
            <div className="text">
              Хранилище фотографий с удобным поиском по разным параметрам
            </div>
          </div>
          <div className="list-item">
            <Bullit variant={1} />
            <div className="text">
              Ежедневное отслеживание основных показателей работы команды
            </div>
          </div>
          <div className="list-item">
            <Bullit variant={1} />
            <div className="text">Контроль за соблюдением рабочих графиков</div>
          </div>
          <div className="list-item">
            <Bullit variant={1} />
            <div className="text">
              Настройка отчетов под любую задачу пользователя
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Control;
