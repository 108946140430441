import Bullit from "../common/Bullit";
import Button from "../common/Button";
import Underlined from "../common/Underlined";

function Local({}) {
  return (
    <div className="halfs">
      <div className="left">
        <div className="small-card">
          <div className="icon">
            <svg
              width="36"
              height="27"
              viewBox="0 0 36 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 22.3302C36 23.3911 35.5786 24.4085 34.8284 25.1587C34.0783 25.9089 33.0609 26.3303 32 26.3303H4C2.93913 26.3303 1.92172 25.9089 1.17157 25.1587C0.421427 24.4085 0 23.3911 0 22.3302V18.3301H36V22.3302Z"
                fill="#CE2028"
              />
              <path d="M0 8.33008H36V18.3304H0V8.33008Z" fill="#22408C" />
              <path
                d="M32 0.332031H4C2.93913 0.332031 1.92172 0.753471 1.17157 1.50364C0.421427 2.25381 0 3.27125 0 4.33215L0 8.33227H36V4.33215C36 3.27125 35.5786 2.25381 34.8284 1.50364C34.0783 0.753471 33.0609 0.332031 32 0.332031Z"
                fill="#EEEEEE"
              />
            </svg>
          </div>
          <div className="text">
            Работа системы не зависит от западных вендоров. Информация клиентов
            передаются только через российские серверы.
          </div>
        </div>
        <div className="small-card">
          <div className="icon">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.6328 5.02874C23.5355 4.91756 23.4162 4.82766 23.2825 4.76469C23.1488 4.70171 23.0035 4.667 22.8558 4.66274C20.5608 4.60274 17.6568 2.14874 15.7368 1.18574C14.5508 0.592739 13.7678 0.201739 13.1798 0.0987389C13.0604 0.0813415 12.9391 0.0816785 12.8198 0.0997389C12.2318 0.202739 11.4488 0.593739 10.2638 1.18674C8.34382 2.14874 5.43982 4.60274 3.14482 4.66274C2.99699 4.66722 2.85166 4.70202 2.71783 4.76498C2.584 4.82794 2.46453 4.91771 2.36682 5.02874C2.1643 5.25787 2.0598 5.55735 2.07582 5.86274C2.56882 15.8857 6.16382 22.0887 12.4718 25.6937C12.6358 25.7867 12.8178 25.8347 12.9988 25.8347C13.1798 25.8347 13.3618 25.7867 13.5268 25.6937C19.8348 22.0887 23.4288 15.8857 23.9228 5.86274C23.9398 5.55739 23.8356 5.2577 23.6328 5.02874ZM18.6168 8.97074L13.2938 16.8257C13.1028 17.1077 12.8028 17.2947 12.5058 17.2947C12.2078 17.2947 11.8768 17.1317 11.6678 16.9227L7.91582 13.1697C7.79332 13.0468 7.72455 12.8803 7.72455 12.7067C7.72455 12.5332 7.79332 12.3667 7.91582 12.2437L8.84282 11.3147C8.96603 11.1927 9.13242 11.1243 9.30582 11.1243C9.47922 11.1243 9.64561 11.1927 9.76882 11.3147L12.2088 13.7547L16.4478 7.49774C16.5459 7.35456 16.6966 7.256 16.8671 7.22358C17.0376 7.19117 17.214 7.22754 17.3578 7.32474L18.4428 8.06074C18.5862 8.15866 18.6849 8.30934 18.7175 8.47985C18.7501 8.65036 18.7139 8.82684 18.6168 8.97074Z"
                fill="#42AA3C"
              />
            </svg>
          </div>
          <div className="text">
            Ваши данные всегда доступны и надежно защищены.
          </div>
        </div>
        <div
          className="image"
          style={{ backgroundImage: "url(/images/servers.png)" }}
        ></div>
      </div>
      <div className="right">
        <h2 className="hero-header">
          Программный продукт для{" "}
          <Underlined text={"российского"} underlineVariant={4} /> рынка
        </h2>
        <div className="sub-header">
          Компания “Юниритейл” начала развивать систему URT в 2014 году. За это
          время мы добились впечатляющей скорости обработки и передачи
          информации.
        </div>
        <div className="sub-header">
          Приложения стабильно работают без зависаний на любом компьютере или
          гаджете.
        </div>
        <div className="sub-header">Система URT полностью совместима:</div>
        <div className="list-item">
          <Bullit />
          <div className="text">
            с программами анализа данных: Power BI, Tableau, Qlik, SAP BI
          </div>
        </div>
        <div className="list-item">
          <Bullit />
          <div className="text">
            с системами ERP: 1С, Microsoft Dynamix, Oracle, SAP, Navision,
            Axapta.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Local;
