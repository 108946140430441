import { useCallback, useState } from "react";
import Bullit from "../common/Bullit";
import Card from "../common/Card";
import Underlined from "../common/Underlined";

function Improve({}) {
  const [openedItemId, setOpenedItemId] = useState("clients");

  const setEmployees = useCallback(() => {
    setOpenedItemId("employees");
  }, [setOpenedItemId]);

  const setClients = useCallback(() => {
    setOpenedItemId("clients");
  }, [setOpenedItemId]);

  const setBase = useCallback(() => {
    setOpenedItemId("base");
  }, [setOpenedItemId]);

  return (
    <div className="improvements">
      <h2 className="hero-header center">
        Планомерно улучшайте{" "}
        <Underlined text={"результаты"} underlineVariant={2} /> (показатели)
        ваших брендов с помощью приложений URT
      </h2>
      <div className="halfs">
        <div className="left">
          {openedItemId !== "clients" && (
            <div className="list-item pointer" onClick={setClients}>
              <div className="text full">
                Развивайте розничных клиентов
              </div>
              <Bullit variant={2} />
            </div>
          )}
          {openedItemId === "clients" && (
            <Card
              noClose={true}
              header="Развивайте розничных клиентов"
              items={[
                "– Анализируйте продажи, трафик и выкладку в каждой торговой точке в реальном времени.",
                "– Планируйте стимулирующие активности и отслеживайте их исполнение.",
                "– Собирайте информацию о трейд-активностях конкурентов.",
                "– Получайте автоматизированные заказы продукции.",
              ]}
            />
          )}
          {openedItemId !== "base" && (
            <div className="list-item pointer" onClick={setBase}>
              <div className="text full">Наращивайте клиентскую базу</div>
              <Bullit variant={2} />
            </div>
          )}
          {openedItemId === "base" && (
            <Card
              noClose={true}
              header="Наращивайте клиентскую базу"
              items={[
                "– Ставьте торговой команде цели по завоеванию новых клиентов.",
                "– Отслеживайте промежуточные результаты.",
                "– Вводите новых клиентов в систему непосредственно в ходе визита.",
              ]}
            />
          )}
          {openedItemId !== "employees" && (
            <div className="list-item pointer" onClick={setEmployees}>
              <div className="text full">Повышайте отдачу от полевых сотрудников</div>
              <Bullit variant={2} />
            </div>
          )}
          {openedItemId === "employees" && (
            <Card
              noClose={true}
              header="Повышайте отдачу от полевых сотрудников"
              items={[
                "– Контролируйте онлайн действия представителей и консультантов.",
                "– Создавайте анкеты с любыми параметрами для полной прозрачности работы персонала.",
                "– Настраивайте гибкие задачи для каждого визита.",
                "– Обучайте сотрудников, передавая материалы на мобильные устройства.",
              ]}
            />
          )}
        </div>
        <div className="right">
          <svg
            width="177"
            height="201"
            className="daub"
            viewBox="0 0 177 201"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M131.208 133.144C106.525 149.718 80.5616 164.09 55.1148 179.382"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M149.632 122.104C147.794 122.982 145.976 123.889 144.176 124.843"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34.1772 164.663C69.3569 147.93 102.809 126.17 135.666 105.326"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M163.234 85.6437C160.079 87.8508 156.835 89.8323 153.578 91.8734"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M105.532 99.7815C97.2391 104.326 89.5988 109.984 81.5199 114.876C67.019 123.655 51.9618 131.318 37.0078 139.268"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M117.885 92.3571C122.031 89.6993 126.127 86.9326 130.128 84.0584"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M59.217 103.953C70.6461 96.882 82.3559 90.3008 93.8185 83.3009"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M107.991 74.8935C111.225 72.7378 114.484 70.6031 117.676 68.3852"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.1069 105.533C36.7795 95.2353 56.2766 82.8172 76.8086 72.3417"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M86.4704 66.794C88.0775 65.8956 89.7045 65.021 91.2784 64.0652"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M101.078 33.4317C83.0943 47.4591 62.4637 60.0265 42.3234 70.7127"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M118.2 19.8851C116.51 21.146 114.783 22.3331 113.011 23.4752"
              stroke="#42AA3C"
              strokeWidth="2.45625"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="157"
            height="150"
            className="sun"
            viewBox="0 0 157 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M121.129 77.399C131.971 65.9906 143.938 55.4935 155.212 44.5055C139.702 43.6727 124.03 41.2625 108.491 41.9565C108.274 31.0876 107.595 20.2812 105.239 9.60919C95.9223 18.4754 85.8213 27.0916 78.112 37.3836C73.5527 25.1535 67.0924 12.9587 59.4092 2.39864C51.9544 14.5745 45.3575 28.3073 41.1758 41.8739C30.6256 39.8597 19.5086 38.7409 8.77517 38.7301C11.9849 49.5254 16.1921 60.0405 20.1745 70.5744C13.3639 77.7886 7.03743 86.3391 2.08927 94.9677C9.25129 96.8561 16.7111 97.5923 23.969 99.0586C22.479 110.344 22.2351 121.787 21.5457 133.14C30.9205 128.857 39.655 123.361 48.1353 117.559C53.4717 127.605 58.1894 137.952 63.4807 148.023C69.5433 138.194 75.6467 128.073 82.3926 118.679C89.8628 126.255 99.5269 131.437 107.621 138.366C106.653 128.117 107.237 117.849 107.158 107.579C118.646 108.018 130.385 107.639 141.896 107.641C134.649 98.5198 127.434 89.3816 120.71 79.8657"
              stroke="#42AA3C"
              strokeWidth="2.93433"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="130"
            height="122"
            className="arrow-left"
            viewBox="0 0 130 122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M125.855 1.67998C116.925 62.8071 62.0793 92.4983 4.8234 99.2495C3.84021 99.3673 3.13331 100.26 3.25111 101.243C3.36891 102.226 4.26144 102.933 5.24462 102.815C64.1045 95.8785 120.228 65.0362 129.407 2.19646C129.552 1.21777 128.872 0.307057 127.889 0.162066C126.911 0.0170759 126 0.696763 125.855 1.67998Z"
              fill="#42AA3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.10862 100.906C8.65494 99.0439 11.8764 96.7421 12.2343 96.4703C21.9348 89.1029 29.9724 80.6256 37.0496 70.6801C37.625 69.8736 37.4346 68.7499 36.6281 68.1745C35.8217 67.5991 34.6981 67.7894 34.1227 68.5959C27.263 78.2423 19.4701 86.466 10.0596 93.6113C9.51136 94.0282 2.26647 99.1979 0.880038 100.353C0.309155 100.825 0.132577 101.269 0.100861 101.377C-0.0894333 101.948 0.0238017 102.397 0.177849 102.723C0.354551 103.09 0.793925 103.625 1.64572 103.897C2.53829 104.177 4.52288 104.304 5.02127 104.377C12.5379 105.491 21.0242 106.081 28.7492 108.219C36.1163 110.258 42.7991 113.711 47.0853 120.629C47.6063 121.472 48.7164 121.73 49.5591 121.209C50.4018 120.688 50.6602 119.578 50.1392 118.735C45.3456 111.001 37.9421 107.036 29.7096 104.757C22.0298 102.632 13.6207 101.989 6.10862 100.906Z"
              fill="#42AA3C"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Improve;
