import Underlined from "../common/Underlined";

function Sfe({}) {
  return (
    <div className="small-card padded">
      <div className="halfs">
        <div className="left">
          <h2 className="hero-header small">
            Приложение для стратегии Sales Force Effectiveness (SFE)
          </h2>
          <div className="sub-header">
            Система URT автоматизирует стратегию роста продаж Sales Force
            Effectiveness (SFE). Компании, применяющие ее, отмечают устойчивый
            рост показателей на 5 - 15%.
          </div>
          <div className="sub-header">
            Экосистема Uniretail позволяет внедрять SFE без лишних консультаций
            и проектирования. Применяя продукты URT, вы автоматически
            используете алгоритмы SFE.
          </div>
        </div>
        <div className="right">
          <div
            className="image"
            style={{ backgroundImage: "url(/images/cart.png)" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Sfe;
